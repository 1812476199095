import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <DefaultPageLayout title="404 Seite nicht gefunden">
      {t('LEIDER_KONNTE_DIE_SEITE_NICHT')}
    </DefaultPageLayout>
  );
}

export default NotFoundPage;
