import React from 'react';
import { Box, Text } from 'theme-ui';

import Header from '../02_molecules/Header/Header';
import Seo from '../../global/Seo';

export interface Props {
  title: string;
  children: React.ReactNode;
}

function DefaultPageLayout({ title, children }: Props) {
  return (
    <>
      <Seo title={title} />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Header theme="light" showButton="back" />
        <Box
          sx={{
            flex: 1,
            '@media screen and (min-width: 1280px)': {
              width: '1200px',
              margin: 'auto',
            },
            pl: '1.25rem',
            pr: '1.25rem',
          }}
        >
          <Text variant="h2" sx={{ fontSize: [2, 4], mb: [3, 7] }}>
            {title}
          </Text>
          {children}
        </Box>
      </Box>
    </>
  );
}

export default DefaultPageLayout;
